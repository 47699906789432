<template>
  <div>
    <title>SIM INVENTORY ~ ADD PRODUCT DATA PROCESS DETAILS BOM</title>
    <section class="content-header">
      <h1>
        Add Product Data Process Details BOM
        <br />
        <h4>Please enter the product transaction data for the BOM detail process</h4>
      </h1>
      <ol class="breadcrumb">
        <li>
          <a href="#"><i class="fa fa-dashboard"></i> Home</a>
        </li>
        <li class="active">Product Transaction Process Details BOM</li>
      </ol>
    </section>
    <section class="content">
      <div v-if="loading" class="load">
        <img width="130" src="@/assets/loader.gif" alt="" />
      </div>
      
      <div class="row">
        <!-- left column -->
        <div class="col-md-12">
          <!-- general form elements -->
          <div class="box box-primary">
            <div class="box-header with-border">
              <h3 class="box-title">Product Data Process Details BOM</h3>
            </div>
            <!-- /.box-header -->
            <!-- form start -->
            <form role="form">
              <div class="box-body">
                <div class="row">
                  <div class="col-xs-9">
                    <label for="exampleInputEmail1">Item Code</label>
                    <input
                      type="text"
                      v-model="isidata.kodebarang"
                      autocomplete="off"
                      class="form-control"
                      placeholder="Item Code"
                    />
                    <input
                      type="hidden"
                      v-model="isidata.idxprodukdproses"
                      autocomplete="off"
                      class="form-control"
                    />
                  </div>
                  <div class="col-xs-3">
                    <label for="exampleInputEmail1">Action</label>
                    <br />
                    <button
                      type="button"
                      @click="getbarang()"
                      class="btn btn-info"
                    >
                      Search
                    </button>
                  </div>
                </div>
                <br />
                <div class="form-group">
                  <label for="exampleInputEmail1">Item Name </label>
                  <input
                    type="text"
                    readonly
                    v-model="isidata.namabarang"
                    autocomplete="off"
                    class="form-control"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Notes</label>
                  <input
                    type="text"
                    v-model="isidata.catatan"
                    autocomplete="off"
                    class="form-control"
                    placeholder="Please Enter Notes"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Unit </label>
                  <input
                    type="number"
                    v-model="isidata.units"
                    autocomplete="off"
                    placeholder="Please Enter Units"
                    class="form-control"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Qty </label>
                  <input
                    type="number"
                    v-model="isidata.qty"
                    autocomplete="off"
                    placeholder="Please Enter Qty"
                    class="form-control"
                  />
                </div>
              </div>
              <div class="box-footer">
                <button
                  @click="saveData()"
                  type="button"
                  class="btn btn-primary"
                > <i class="fa fa-save"></i>
                  Save
                </button>
                &nbsp;
                <!-- <router-link to="/produk">
                  <button class="btn btn-success">Kembali</button>
                </router-link> -->
              </div>
            </form>
          </div>
        </div>
       
      </div>
    </section>
  </div>
</template>
<script>
import swal from "sweetalert";
var axios = require("axios");
export default {
  name: "addprod",
  data() {
    return {
      loading: false,
      isidata: {
        kodebarang: "",
        idxprodukdproses: "",
        namabarang: "",
        catatan: "",
        qty: ""
      }
    };
  },
  created() {
    this.isidata.idxprodukdproses = this.$route.params.id;
  },
  methods: {
    async getbarang() {
      this.loading = true;
      const tokenlogin = sessionStorage.getItem("token");
      // const urlAPIget = "http://26.183.23.191/inventory/backend/inventory/api/barang/getbarangbykode_barang?kode_barang=" + this.isidata.kodebarang;
      const urlAPIget =
        this.$apiurl +
        "barang/getbarangbykode_barang?kode_barang=" +
        this.isidata.kodebarang;
      const headers = {
        Accept: "application/json",
        Authorization: tokenlogin
      };
      axios
        .get(urlAPIget, { headers })
        .then((resp) => {
          // console.log(resp);
          if (resp.data.pesan == "Data not found") {
            swal({
              icon: "warning",
              title: "Warning",
              text: "Sorry, Item Code not found",
              showConfirmButton: false
            });
          } else {
            this.isidata.namabarang = resp.data.data.nama_barang;
          }
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async saveData() {
      this.loading = true;
      var kdbrg = this.isidata.kodebarang;
      var nmbrg = this.isidata.namabarang;
      var idxpdp = this.isidata.idxprodukdproses;
      var nts = this.isidata.catatan;
      var jml = this.isidata.qty;

      if (kdbrg == "" || nmbrg == "" || idxpdp == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Item Code / Item Name can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (nts == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Notes can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (jml == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Qty can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else {
        // const tokenlogin = localStorage.getItem("token");
        var kodeuser = sessionStorage.getItem("kodeuser");
        var paramdata = {
          id_produk_d_proses: idxpdp,
          kode_barang: kdbrg,
          notes: nts,
          qty: jml,
          kode_user: kodeuser
        };

        var tokenlogin = sessionStorage.getItem("token");
        // var urlAPIsavebrg = "http://26.183.23.191/inventory/backend/inventory/api/produk_d_proses/saveproduk_d_proses";
        var urlAPIsavedata =
          this.$apiurl + "produk_d_proses_bom/saveproduk_d_proses_bom";

        var headers = {
          Accept: "application/json",
          Authorization: tokenlogin
        };
        axios
          .post(urlAPIsavedata, paramdata, { headers })
          .then((resp) => {
            if (resp.data.status == true) {
              swal({
                icon: "success",
                title: "Success",
                text: "Save data successfully",
                showConfirmButton: false
              });
              this.loading = false;
              this.$router.push({ name: "produk" });
            } else {
              swal({
                icon: "warning",
                title: "Warning",
                text: "Save data failed",
                showConfirmButton: false
              });
              this.loading = false;
              this.$router.push({ name: "produk" });
            }
          })
          .catch((err) => {
            swal({
              icon: "warning",
              title: "Warning",
              text: err.response.data,
              showConfirmButton: false
            });
            console.log(err);
            this.loading = false;
          });
      }
    }
  }
};
</script>
